.separador{
	&__25{
		margin-top: 12px;
		margin-bottom: 12px;
		@media #{$media-movil}{
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
	&__50{
		margin-top: 25px;
		margin-bottom: 25px;
		@media #{$media-movil}{
			margin-top: 7px;
			margin-bottom: 7px;
		}
	}
	&__100{
		margin-top: 50px;
		margin-bottom: 50px;
		@media #{$media-movil}{
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}
