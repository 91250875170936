html, body{
    font-size: 14px;
    @include montserrat;
}

body{
    @media #{$media-movil}{
    	padding-top: $tamanoMenuLateralMovil;
    }
}

a{
    cursor: pointer;
    color: $gris-texto2;
    &:hover{
        color: $gris-texto3;
    }
}

b,strong{
    font-weight: 700;
}


.border-gris{
    webkit-box-shadow: 0px 0px 1px 1px $gris-linea1;
    -moz-box-shadow:    0px 0px 1px 1px $gris-linea1;
    box-shadow:         0px 0px 1px 1px $gris-linea1;
}


.menu_header_backdrop{
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    cursor: pointer;
    background-color: rgba($gris-bg3, 0.8);
}

.lead{
    color: $gris-texto1;
    line-height: 1.6rem;
    font-size: 1.142857142857143rem;
    margin-bottom: 10px;
    strong{
        color: $gris-texto3;
    }
}
