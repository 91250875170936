.nbs-flexisel-container {
    position:relative;
    max-width:100%;
}
.nbs-flexisel-ul {
    position:relative;
    width:99999px;
    margin:0px;
    padding:0px;
    list-style-type:none;
}

.nbs-flexisel-inner {
    position: relative;
    overflow: hidden;
    float:left;
    width:100%;
}

.nbs-flexisel-item {
    float:left;
    margin:0px;
    padding:0px;
    cursor:pointer;
    position:relative;
}
.nbs-flexisel-item img {
    max-width: 100%;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

/*** Navigation ***/

.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
    padding:5px 10px;
    position: absolute;
    cursor: pointer;
    z-index: 4;
    top:40%;
    background: $gris-bg3;
    color: $blanco;
}

.nbs-flexisel-nav-left {
    left: 10px;
}

.nbs-flexisel-nav-left:before {
    content: "<"
}

.nbs-flexisel-nav-left.disabled {
    opacity: 0.4;
}

.nbs-flexisel-nav-right {
    right: 5px;
}

.nbs-flexisel-nav-right:before {
    content: ">"
}

.nbs-flexisel-nav-right.disabled {
    opacity: 0.4;
}
