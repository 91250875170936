.padding-top{
    padding-top: 1em;
}
.breadcrumbs{
    position: relative;
    padding-left: 25px;
    font-size: 12px;
    text-transform: uppercase;
    &:after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 0 0 12px 12px;
        border-color: transparent transparent #ffffff transparent;
        left: 0;
        top: 1px;
    }
    >a{
        color: $blanco;
        display: inline-block;
        text-decoration: none;
        font-size: 12px !important;
        text-transform: uppercase;
        @media #{$media-movil}{
            font-size: 0.7571428571428571rem;
        }
        &:hover, &:active{
            opacity: 0.9;
        }
        &:after{
            display: inline-block;
            content: "/";
            margin-right: 8px;
            margin-left: 8px;
        }
        &:last-of-type{
            &:after{
                content: none;
            }
        }
    }
}
