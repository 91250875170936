.menu_fixed-left{
    top: 0;
    left: 0;
    height: 100%;
    position: fixed;
    width: $tamanoMenuLateral;
    background-color: $gris-bg2;
    margin-top: $tamanoMenuLateralup;
    z-index: 100;
    @media #{$media-movil}{
    	display: none;
    }
    .menu_fixed--logo{
        position: relative;
        text-align: center;
        width: $tamanoMenuLateral;
        height: $tamanoMenuLateralup;
        background-color: $rojo-derco;
        z-index: 2;
        display: none;
        img{
            margin-top: 30px;
        }
    }
    .menu_fixed--menu{
        position: relative;
        margin: 0;
        padding: 0;
        z-index: 2;
        background-color: $gris-bg2;
        height: calc(100% - #{$tamanoMenuLateral});
        li{
            @include flexbox;
            @include align-items(center);
            height: (100%) /3;
            text-align: center;
            border-bottom: 1px solid darken($gris-bg2, 3);
            cursor: pointer;
            font-size: 0.9rem;
            &:hover, &.active{
                a{
                    @include transition(0.3);
                    color: $blanco;
                }
                img{
                    @include transition(0.3);
                    -webkit-filter: brightness(200%) grayscale(100%);
                    filter: brightness(200%) grayscale(100%);
                }
            }
            a{
                width: 100%;
                display: block;
                text-align: center;
                color: $azul-texto1;
            }
        }
    }
}
.menu_fixed--contenido{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    background-color: $blanco;
    padding-left: $tamanoMenuLateral;
    padding-right: $tamanoMenuLateral - 30;
    z-index: 1;
    &.active{
        z-index: 2;
    }
}
.menu_fixed--cerrar{
    top: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    position: fixed;
    cursor: pointer;
    width: $tamanoMenuLateral - 30;
    background-color: $rojo-derco;
    display: none;
    .menu_fixed--cerrar_flex{
        height: 100%;
        width: 100%;
        @include inline-flex;
        @include align-items(center);
        >div{
            font-size: 2rem;
            width: 100%;
            text-align: center;
            color: $blanco;
            p{
                font-size: 1rem;
            }
        }
    }
}
