html,body,h1,h2,h3,h4,h5,h6,p{
    padding: 0;
    margin: 0;
}
img{
    max-width: 100%;
    display: inline-block;
}

.row{
    margin-right: 0;
    margin-left: 0;
}
.container-fluid, .container{
    padding-right: 0;
    padding-left: 0;
}


a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}
