footer.footer{
    background: #f6f6f6;
    .seccionMarcasFooter{
        padding-top: 3em !important;
        border-top: 1px solid #e3e3e3;
        padding-bottom: 3em !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
        text-align: center;
        h3{
            font-size: 16px;
            letter-spacing: 5px;
            margin-bottom: 0;
            text-align: left;
        }
        img{
            padding-top: 20px;
            display: inline-block;
            position: relative;
        }
    }

    .seccionLegalesFooter{
        padding-top: 3em !important;
        border-top: 1px solid #e3e3e3;
        padding-bottom: 3em !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
        text-align: left;
        color: #999999;
        p{
            font-size: 12px;
            padding: 0 50px 0 0;
            line-height: 18px;
            a{
                color: #428bca;
            }
        }
    }


    #inner-footer{
        padding: 1.5em 0;
        background: white;
        padding-right: 50px;
        padding-left: 50px;
        border-top: 1px solid #e3e3e3;
        color: #9E9E9E;
    }
    .footer_columnas{
        padding: 50px 20px 30px 50px;
        @media #{$media-tablet}{
            padding: 20px 15px;
        }
        >div{
            height: 100%;
            border-right: 1px solid $gris-linea1;
            padding-left: 3em;
            &:last-of-type{
                border-right-color: transparent;
            }
            @media #{$media-tablet}{
            	border-right-color: transparent;
            }
            &:first-child{
                padding-left: 1em;
            }
        }
        h5{
            color: $gris-texto3;
            font-size: 1.642857142857143rem;
            a{
                color: $gris-texto3;
                text-decoration: none !important;
                &:hover{
                    color: $gris-texto1;
                }
            }
        }
        h6{
            margin-top: 30px;
            font-size: 0.9285714285714286rem;
            margin-bottom: 20px;
            color: #616161;
            strong{
                color: $gris-texto3;
            }
        }
        p{
            color: $gris-texto1;
            margin-bottom: 10px;
            font-size: 0.9285714285714286rem;
        }
    }
    .attribution{
        >span{
            @media #{$media-movil}{
                display: block;
                margin-top: 20px;
                color: $gris-texto1;
            }
        }
        a.float-r{
            @media #{$media-movil}{
                float: none;
                display: block;
                margin-top: 15px;
                padding-left: 10px;
            }
        }
    }
}
