.historia_Latinoamerica{
    height: 400px;
    @media #{$media-movil}{
        height: auto;
    }
    &__banner1, &__banner2{
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 50px;
        @media #{$media-movil}{
            padding: 5px;
            height: 270px;
            padding-top: 30px;
            text-align: center;
        }
    }
}

.presencia_paises{
    padding: 100px 80px;
    background-repeat: no-repeat;
    background-position: right bottom;
    .title-2{
        @media #{$media-movil}{
            font-size: 16px;
        }
    }
    @media #{$media-movil}{
        padding: 50px 5px;
        background-size: contain;
        h4{
            text-align: center;
        }
    }
    &--pais{
        padding-left: 30px;
        background-repeat: no-repeat;
        background-position: left top;
        background-image: url('../img/icons/iconMapMarkRed.svg');
    }
}

.video_banner{
    height: 450px;
    position: relative;
    padding: 0;
    background-size: cover;
    background-position: center center;
    iframe{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: none;
    }
    @media #{$media-tablet}{
        height: 300px;
        text-align: center;
    }
    @media #{$media-movil}{
    }
    #video{
        display: none;
        height: 450px;
        width: 100%;
        @media #{$media-tablet}{
            height: 300px;
        }
    }
    video{
        width: 100%;
        height: 100%;
    }
    .boton__nplay{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        @media #{$media-movil}{
            bottom: -80px;
        }
    }
    .boton__npausa{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        @media #{$media-movil}{
            bottom: -80px;
        }
    }
    p{
        color: $blanco;
        font-size: 20px;
        padding: 20px 20px 0;
        line-height: 1;
        strong{
            font-weight: 500;
        }
    }
    h4{
        padding-left:20px;
    }
    .video_banner{}
}
.oncima{
    position: absolute;
    top: 20%;
    left: 10px;
}
.ultimas_noticias{
    position: relative;
    @media #{$media-tablet}{
        text-align: left;
        .box_noticia{
            text-align: left;
        }
    }
}
.noOverflow{
    overflow: hidden;
}
.pilares{
    line-height: 1.6;
    font-size: 1.142857142857143rem;
    color: #8b8b8b;
}
.minicons{
    margin: 0 30px 0 0;
    img{
        width: 35px;
        margin: 0 15px 0 0;
    }
}
.tolinksblue{
    a{
        color:#557ACA;
        text-decoration: underline;
    }
}
.imgtitle{
    width: 50%;
    margin: 0 auto;
    @media #{$media-movil}{
        width: 80%;
    }
}
.title-1{
    &--contact{
        font-family: "montserrat";
        font-weight: 700;
        color: #4b5560;
        font-size: 30px;
        margin: 20px 0 35px;
    }
}
.respaldotext{
    color:#fff;
    ul{
        color:#fff;
        font-size: 12px;
        text-align: left;
        line-height: 1;
    }
}
.contactspace{
    padding: 172px 0 20px;
    text-align: left;
    .title-1{
        font-size: 3.5rem;
    }
    .title-2{
        font-weight: 300;
        font-size: 2.5rem;
    }
    .textmin{
        font-size: 1.5rem;
        font-weight: 300;
    }
    @media #{$media-movil}{
        padding: 12px 0 20px;
        text-align: center;
    }
}
.nopadd{
    padding: 0;
}
.paddline{
    padding: 0 20px;
}
.formcontact{
    .wpcf7-form{
        p{
            text-align: left;
        }
        .toleft{
            text-align: left;
            a{
                display: inline;
                background: none;
                border:none;
                padding: 0;
            }
        }
        .mensaje{
            textarea{
                height: 200px;
            }
        }
        .wpcf7-submit{
            display: block;
            margin: 0 auto;
        }
    }
}
.repuest{
    .topto{
        .textos{
            top: 30px;
            width: 80%;
            text-align: left;
            left: 10%;
            .title-1{
                font-size: 2.8rem;
            }
            p{
                font-size: 20px;
            }
        }
        @media #{$media-movil}{
           .textos{
                top: 30px;
                text-align: left;
            }
        }
    }
    .midto{
        .textos{
            top: 30%;
            width: 40%;
            text-align: left;
            right: 5%;
            .title-1{
                font-size: 18px;
            }
        }
        @media #{$media-movil}{
           .textos{
                top: 30%;
                text-align: left;
            }
        }
    }

}
.sobre_derco{
    $sobre_derco: 600px;
    height: $sobre_derco;
    position: relative;
    .title-2{
        font-size: 34px;
    }
    .subtitle-2 {
        font-size: 16px
    }
    &--video, &--mitad{
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        @include transition(0.3);
        &:hover{
            opacity: 0.95;
            -o-transform: scale(1.1);
            transform:scale(1.1);
        }
        .textos{
            position: absolute;
            width: 100%;
        }
    }
    &--video{
        height: $sobre_derco;
        display: block;
        .textos{
            bottom: 40%;
        }
        @media #{$media-movil}{
            height: ($sobre_derco)/2;
            .textos{
                bottom: 10px;
            }
        }
    }
    &--mitad{
        height: ($sobre_derco)/2;
        display: block;
        .textos{
            bottom: 40%;
        }
    }
}
.textoss{
    position: absolute;
    width: 100%;
    bottom: 18%;
    li{
        margin-top: 8px !important;
    }
}
.listaDesplegable{
    max-width: 1000px;
    margin: 0 auto;
}

.banner_interno{
    padding: 40px 60px;
    // height: 300px;
    background-size: cover;
    color: $blanco;
    background-color: $rojo-derco;
    background-size: cover;
    background-position: center;
    @media #{$media-movil}{
    	padding: 10px;
    }
    .text-center{
        text-align: center;
    }
    h1{
        font-weight: 300;
        font-size: 4.285714285714286rem;
        @media #{$media-movil}{
        	font-size: 2.285714285714286rem;
            text-align: center;
        }
    }
    p{
        font-size: 1.357142857142857rem;
        @media #{$media-movil}{
        	font-size: 1.15rem;
            text-align: center;
        }
    }
}

.banner_noticias{
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    .banner_noticias--textos{
        max-width: 700px;
        h1.title-3{
            @media #{$media-movil}{
            	font-size: 1.7rem;
                margin-bottom: 10px;
            }
        }
    }
}
.seccion_pilares{
    .container{
        padding-right: 20em;
        @media#{$media-tablet}{
            padding-right: 0em;
        }

    }
    .image{
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        position: absolute;
        right: 0;
        width: 32%;
        @media #{$media-tablet}{
            display: none;

        }
    }
}
.colrepuestos{
    padding: 10px 10% 40px;
    .colimage{
        img{
            width: 100%;
            height: auto;
        }
    }
    .thelink{
        text-align: center;
        background: #efefef;
        padding: 10px;
    }
}
.seccion_nosotros{
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: right bottom;
    .title-0{
        @media #{$media-movil}{
            font-size: 28px;
        }
    }
    @media #{$media-tablet}{
    	background-size: auto 50%;
        background-position: right center;
    }
    @media #{$media-movil}{
    	background-image: none!important;
    }
    p{
        strong{
            font-weight: 400;
            line-height: 1.6;
        }
    }
}

.seccionPqr{
    margin-top: 40px;
}

.seccionMarcas{
    margin-top: 20px;
    margin-bottom: 20px;
    .subtitle-rojo{
        font-size: 18px;
        padding: 1em 0;
    }
    .text-center{
        text-align: center;
    }
    &__container{
        text-align: center;
        .col-md-4{
            width: 30%;
            float: none;
            display: inline-block;
            @media #{$media-movil}{
                width: 48%;
            }
            @media #{$media-extra-movil}{
                width: 95%;
            }
        }
    }
    &__logos{
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(center);
        text-align: center;
        figure{
            margin: 20px 0;
            min-width: 120px;
            @include flex(1 1 25%);
            @media #{$media-movil}{
                @include flex(1 1 30%);
                max-width: 110px;
                min-width: inherit;
                margin: .5em;
            }
        }
    }
    .title-2{
        @media #{$media-extra-movil}{
            margin: 0 auto;
            font-size: 16px;
            padding: 1.5em 0;
            max-width: 200px;
            letter-spacing: 2px;
        }
    }
    &__marca{
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        max-width: 500px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid $gris-linea1;
        >img{
            //height: 110px;
        }
    }
    .decripmarca{
        height: 110px;
        padding: 15px 0;
        overflow: hidden;
    }
}
.somosNavigation{
    border-bottom: 1px solid $gris-linea1;
    a{
        font-weight: bold;
        padding: 30px 30px;
        display: inline-block;
        &:hover, &.active{
            color: $rojo;
        }
    }
}
.seccion_masDerco{
    background: #F5F5F5;
    padding: 2em 0;
    .seccion_masDerco__item{
        a{
            background: white;
            border: 1px solid #EEEEEE;
        }
        .subtitle-2{
            padding: 1.5em 1.5em 1.5em 1.5em;
        }
        .subtitle-3{
            padding: 0 1.5em 1.5em 1.5em;
        }
    }

}

.seccionMarcasFooter{
    &__container{
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(center);
        text-align: center;
        figure{
            min-width: 100px;
            // max-width: 140px;
            @include flex(1 1 25%);
            img{
                opacity: 0.7;
                filter: grayscale(100%);
                -o-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                -moz-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            }
        }
    }
}

.seccion_masDerco{
    .link_red{
        font-size: 0.9rem;
        padding-left: 15px;
    }
    a{
        display: block;
        position: relative;
    }
    &__item{
        &:nth-of-type(2){
            a:after{
                content: "";
                z-index: 1;
                width: 168px;
                height: 66px;
                position: absolute;
                left: 20px;
                bottom: 65px;
                background-image: url('../img/home/trabaje_con_nosotros.png');
            }
        }
        @media #{$media-movil}{
            margin-bottom: 25px;
            padding-bottom: 20px;
            border-bottom: 1px solid $gris-linea1;
            &:last-of-type{
                margin-bottom: 01px;
            }
        }
    }
}

.seccion_formulario{
    margin-top: 40px;
    margin-bottom: 40px;
    display:flex;
    -webkit-webkit-align-items: center;
    -moz-webkit-align-items: center;
    -ms-webkit-align-items: center;
    -o-webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    .centerContent{
        max-width: 1000px;
        margin: 0 auto;
        display: inline-block;
    }
    form{
        .row{
            .col-xs-12{
                padding-left: 0 !important;
            }
            input[type="submit"]{
                padding: 1.1em 4em;
                cursor:pointer;
                float: right;
                @media #{$media-movil}{
                    float: none;
                    margin: 0 auto;
                    width: 100%;
                }
            }
        }
    }
    @media #{$media-movil}{
        margin-top: 15px;
    }
    h4{
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 1.1rem;
        color: $gris-texto2;
    }
}

.pageTexto{
    h1,h2,h3,h4,h5,h6{
        color: $rojo;
        margin-bottom: 0.5%;
        @include montserrat-semibold;
    }
    a{
        color: $rojo;
        &:hover{
            color: $negro;
        }
    }
}
