.box_menu{
    position: relative;
    border-bottom: 1px solid $gris-linea1;
    border-right: 1px solid $gris-linea1;
    @include flexbox;
    @include align-items(center);
    >div{
        width: 100%;
    }
}
.box_menu1{
    padding: 35px 15px;
    height: 33.3vh;
    img{
        position: absolute;
        right: 20px;
        top: 25px;
    }
    h3{
        font-weight: 700;
        font-size: 1.3rem;
    }
    p{
        max-width: 200px;
        color: $gris-texto1;
    }
    a{
        color: $rojo;
        font-weight: bold;
        font-size: 0.9rem;
    }
}

.box_menu2{
    padding: 20px 15px;
    height: 33.3vh;
    h3{
        font-weight: 700;
        font-size: 1.5rem;
    }
    p{
        color: $gris-texto1;
    }
    a{
        color: $rojo;
        font-weight: bold;
        font-size: 0.9rem;
    }
}

.box_menu3{
    padding: 20px 15px;
    height: 33.3*2vh;
    h3{
        font-weight: 700;
        font-size: 1.6rem;
        color: $rojo;
    }
    h4{
        font-size: 3.5rem;
        font-weight: 700;
    }
    p{
        color: $gris-texto1;
        font-size: 0.9rem;
    }
}

.box_menu4{
    height: 50vh;
    background-size: cover;
    position: relative;
    padding: 20px 15px;
    h3{
        font-weight: 400;
        font-size: 1.7rem;
    }
    h4{
        font-size: 2rem;
        font-weight: 700;
    }
    p{
        color: $gris-texto1;
        font-size: 0.9rem;
    }
}

.box_mainnoticia{
    margin-top: 0;
    padding: 2% 0;
    background-color: $gris-bg1;
    @media #{$media-movil}{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }
    &--leermas{
        margin-top: 30px;
        @media #{$media-movil}{
            margin-top: 10px;
        }
    }
}

.box_noticia{
    border: 1px solid $gris-linea1;
    padding: 30px;
    height: 280px;
    margin-bottom: 15px;
    @media #{$media-movil}{
        height: auto;
    }
    &__header{
        //
    }
    .text-fecha{
        font-size: 1rem;
        &:before{
            @media #{$media-tablet}{
                left: 0;
                right: inherit;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &--texto{
        display: -webkit-box;
        height: 63px;
        margin: 0 auto;
        font-size: 14px;
        max-width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        @media #{$media-movil}{
            display: none;
        }
    }
}

.boxOfertas{
    @include clear-b;
    padding: 30px;
    background-color: $gris-bg1;
    &__titulo{
        font-size: 20px;
        color: $gris-texto3;
        margin-bottom: 30px;
    }
    &__ofertas{
        height: 350px;
        overflow-y: auto;
        @include clear-b;
        padding-right: 5px;
        @media #{$media-tablet}{
            height: auto;
        }
        &--oferta{
            @include clear-b;
            padding: 20px 5px;
            margin-bottom: 10px;
            background-color: $blanco;
            font-size: 1rem;
            min-height: 100px;
            &-nombre{
                font-weight: bold;
                padding-right: 45px;
                span{
                    top: 0;
                    right: 0;
                    font-style: italic;
                    position: absolute;
                    font-weight: normal;
                }
            }
            &-descripcion{}
            &-link{
                color: $rojo;
                text-decoration: underline;
            }
        }
    }
}
