label.btn{
    input{
        display: none!important;
    }
}

.boton{
    border: none;
    outline: none;
    cursor: pointer;
    padding: 13px 38px;
    text-align: center;
    letter-spacing: 1.3px;
    display: inline-block;
    vertical-align: middle;
    @include transition(0.3);
    @include inline-flex;
    @include align-items(center);
    @include justify-content(center);
    &__gris3{
        background-color: $gris-bg3;
        border: 1px solid $gris-bg3;
        color: $blanco;
        &:hover{
            color: $gris-bg3;
            border-color: $gris-bg3;
            background-color: $blanco;
        }
    }
    &__rojo{
        background-color: $rojo;
        border: 1px solid $rojo;
        color: $blanco;
        &:hover{
            color: $rojo;
            border-color: $rojo;
            background-color: $blanco;
        }
    }
    &__blanco{
        border: 1px solid $blanco;
        background-color: $blanco;
        font-weight: 500;
        color: $gris-texto3;
        &:hover{
            border-color: $blanco;
        }
    }
    &__transparente{
        color: $gris-bg2;
        border: 1px solid $gris-bg2;
        background-color: transparent;
        &:hover{
            color: $blanco;
            border-color: $gris-bg2;
            background-color: $gris-bg2;
        }
    }
    &__play{
        width: 70px;
        height: 70px;
        background-color: $rojo;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/icons/icon_play.png');
        @include border-radius(50%);
        &:hover{
            opacity: 0.9;
            background-color: lighten($rojo, 10);
        }
    }
    &__nplay{
        width: 70px;
        height: 70px;
        background-color: $rojo;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/icons/icon_play.png');
        @include border-radius(50%);
        &:hover{
            opacity: 0.9;
            background-color: lighten($rojo, 10);
        }
    }
    &__npausa{
        display: none;
        width: 70px;
        height: 70px;
        background-color: rgba(30, 106, 173, 0);
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/icons/icon_pausa.png');
        @include border-radius(50%);
        opacity: 0;
        &:hover{
            opacity: 0.9;
        }
    }
}


.icon{
    margin-left: 3px;
    margin-right: 3px;
    display: inline-block;
    vertical-align: middle;
    @include transition(0.3);
    &__scrollDown{
        width: 15px;
        height: 15px;
        background-image: url('../img/icons/iconScrollDown.svg');
        @include animacion(arrow, 1.5s);
    }
    &__carruLeft,
    &__carruRight{
        width: 40px;
        height: 40px;
    }
    &__carruLeft{
        background-image: url('../img/icons/iconCarruselLeft.svg');
    }
    &__carruRight{
        background-image: url('../img/icons/iconCarruselRight.svg');
    }
    &__warning{
        width: 14px;
        height: 14px;
        background-image: url('../img/icons/icon_warning.png');
    }
    &__redes{
        width: 42px;
        height: 42px;
        margin: 0 .8em;
        &:first-child{
            margin-left:0;
        }
        @include border-radius(50%);
        background-repeat: no-repeat;
        background-position: center center;
        &--twitter{background-image: url('../img/icons/icon_twitter.png');}
        &--facebook{background-image: url('../img/icons/icon_facebook.png');}
        &--instagram{background-image: url('../img/icons/icon_instagram.png');}
        &--rojo{
            background-color: $rojo-derco;
            &:hover{
                background-color: $gris-bg3;
            }
        }
    }
}
