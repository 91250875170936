body.home{
    padding-left: $tamanoMenuLateral;
    @media #{$media-movil}{
    	padding-left: 0;
    }
    header.main_header{
        margin-left: -$tamanoMenuLateral;
        @media #{$media-movil}{
        	margin-left: 0;
        }
        .main_header--logo{
            position: fixed;
            display: flex;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            -o-align-items: center;
            align-items: center;   
            -webkit-justify-content: center;
            -moz-justify-content: center;
            -ms-justify-content: center;
            -o-justify-content: center;
            justify-content: center;
            @media #{$media-movil}{
                position: absolute;
            }
        }
    }
}
.seccionMarcas{
    h3{
        font-weight: 500 !important;
    }
}
// ↓↓↓ Carrusel del home
#carouselHome{
    .carousel_item{
        height: calc(100vh - #{$tamanoMenuLateralup});
        background-size: cover;
        background-position: center center;
        @media #{$media-movil}{
            height: calc(100vh - #{$tamanoMenuLateralMovil});
        }
        .carousel_contenido{
            height: 100%;
            @include inline-flex;
            @include align-items(center);
            padding: 0 100px;
            .carousel_contenido--textos{
                color: $blanco;
                >*{
                    animation-duration: 0.7s;
                }
                h2{
                    font-size: 5rem;
                    line-height: 4rem;
                    @include montserrat-extralight();
                }
                h3{
                    line-height: 7rem;
                    margin-left: -10px;
                    font-size: 9.285714285714286em;
                    @include montserrat-bold();
                }
                p{
                    max-width: 465px;
                    margin-top: 25px;
                    font-size: 1.5rem;
                    @include montserrat-light();
                    line-height: normal;
                }
                .boton{
                    &:hover{
                        color: $blanco;
                        border-color: $blanco;
                        background-color: transparent;
                    }
                }
            }
            @media #{$media-movil}{
                padding: 0 15px;
                width: 100%;
                @include justify-content(center);
                .carousel_contenido--textos{
                    text-align: center;
                    h2{
                        font-size: 3rem;
                        line-height: 2.4rem;
                    }
                    h3{
                        margin-left: 0;
                        font-size: 3rem;
                        line-height: 3rem;
                    }
                    p{
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
    .cassusel_ScrollDown{
        left: 100px;
        bottom: 50px;
        position: absolute;
        z-index: 1;
        color: $blanco;
        font-size: 0.8rem;
        letter-spacing: 1.6px;
        @media #{$media-movil}{
            left: 0;
            right: 0;
            bottom: 80px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
    .controls{
        @media #{$media-movil}{
            display: none;
        }
        position: absolute;
        width: 40px;
        height: 124px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0px;
        >.icon{
            margin: 0;
            margin-bottom: 2px;
        }
        .carruselcounter{
            width: 50px;
            float: right;
            color: #ffffff;
            margin-top: 10px;
            text-align: right;
            padding-right: 2px;
            letter-spacing: 1px;
            >*{
                display: inline-block;
                font-weight: 400;
            }
            strong{
                font-size: 16px;
            }
            span{
                font-size: 11px;
            }
        }
    }
}
span{
    .wpcf7-list-item{
        margin: 0 !important;
        label{
            font-weight: 400 !important;
        }
    }
}