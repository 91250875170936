ul{
    &.lista_general{
        li{
            list-style-image: url('../img/icons/icon_lista-rojo.png');
            margin-top: 20px;
        }
    }


    &.listaDesplegable{
        li{
            position: relative;
            padding: 20px;
            cursor: pointer;
            list-style: none;
            border-bottom: 1px solid $gris-linea1;
            list-style-image: url('../img/icons/icon_lista-rojo.png');
            &:last-of-type{
                border-bottom: none;
            }
            &::after{
                content: "";
                top: 20px;
                width: 19px;
                right: 15px;
                height: 11px;
                display: block;
                position: absolute;
                background-image: url('../img/icons/icon_acordionList.svg');
            }
            &.active{
                &::after{
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
                    transform: rotate(180deg);
                    filter: grayscale(100%);
                    opacity: 0.5;
                }
            }
            p{
                display: none;
                margin-top: 10px;
            }
        }
    }
}
