.detalleNoticias{
    &__contenido{

    }
    &__redes{
        .icon.icon__redes{
            width: 15px;
            height: 15px;
            border-radius: 0;
            background-size: cover;
            display: block;
            margin-top: 10px;
            &.icon.icon__redes.icon__redes--twitter{
                background-image: url('../img/icons/icon_twitter--header.png');
            }
            &.icon.icon__redes.icon__redes--facebook{
                background-image: url("../img/icons/icon_facebook--header.png");
            }
        }
    }
}
.bwg_slideshow_image_wrap_0{
    background: #eeeff0 !important;
    margin: 0 auto;
    .bwg_slideshow_dots_thumbnails_0{
        display: none;
    }
}
#spider_slideshow_right-ico_0{
    border-radius: 0 !important;
    right: -20px !important;
}
#spider_slideshow_left-ico_0{
    border-radius: 0 !important;
    left: -20px !important;
}
.fa-chevron-left:before {
    content: "←" !important;
}
.fa-chevron-right:before {
    content: "→" !important;
}
.tablepress thead th {
    background-color: #35464e;
    color:#fff;
}