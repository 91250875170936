// @import 'https://fonts.googleapis.com/css?family=Roboto';

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700');

// @font-face {
//   font-family: 'bebas_neue_regularregular';
//   src:  url('../fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/BebasNeueRegular.otf')  format('opentype'),
//         url('../fonts/BebasNeueRegular.woff') format('woff'),
//         url('../fonts/BebasNeueRegular.ttf')  format('truetype'),
//         url('../fonts/BebasNeueRegular.svg#BebasNeueRegular') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


@mixin montserrat {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
@mixin montserrat-extralight {
	font-family: 'Montserrat', sans-serif;
	font-weight: 200;
}
@mixin montserrat-light {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}
@mixin montserrat-semibold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
}
@mixin montserrat-bold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6{
	font-family: 'Montserrat';
}

.link_red{
	color: $rojo;
	font-weight: bold;
}
.goto_site{
	color: $rojo;
	font-size: 0.8571428571428571rem;
	font-weight: 400;
	&::before{
		width: 16px;
		height: 16px;
		content: " ";
		margin-right: 5px;
		display: inline-block;
		vertical-align: middle;
		background-image: url('../img/icons/icon_goto.svg');
	}
}

.title{
	&-0{
		font-family: "montserrat";
		font-weight: 700;
		color: $gris-texto3;
		font-size: 4.285714285714286rem;
		@media #{$media-movil}{
			font-size: 3.128571428571429rem;
		}
	}
	&-1{
		font-family: "montserrat";
		font-weight: 700;
		color: $gris-texto3;
		font-size: 2.857142857142857rem;
		@media #{$media-movil}{
			font-size: 1.7rem;
		}
	}
	&-2{
		font-family: "montserrat";
		line-height: 1;
		font-weight: 700;
		color: $gris-texto3;
		letter-spacing: 1px;
		font-size: 1.428571428571429rem;
		@media #{$media-movil}{
			letter-spacing: normal;
		}
	}
	&-3{
		font-weight: 400;
		font-family: "montserrat";
		font-size: 3.142857142857143rem;
	}
	&-4{
		font-weight: 500;
		font-family: "montserrat";
		text-transform: capitalize;
		font-size: 2.142857142857143rem;
	}
	&-blanco{
		color: $blanco;
	}
	&-rojo{
		color: $rojo;
	}
}

.subtitle{
	&-1{
		font-family: "montserrat";
		font-weight: 300;
		color: $rojo;
		font-size: 1.6rem;
		@media #{$media-movil}{
			font-size: 1.428571428571429rem;
		}
	}
	&-2{
		font-family: "montserrat";
		font-weight: 400;
		font-size: 1rem;
		color: $gris-texto3;
	}
	&-3{
		font-size: 1.1rem;
		font-weight: 400;
		color: $gris-texto1;
		font-family: "montserrat";
		text-transform: uppercase;
	}
	&-blanco{
		color: $blanco;
	}
	&-rojo{
		color: $rojo;
	}
}

.detalleNoticias__contenido{
	font-size: 18px;
	line-height: 1.8;
	@media #{$media-tablet}{
		font-size: 17px;
	}
	@media #{$media-movil}{
		font-size: 16px;
	}
	p{
		color: #8b8b8b;
	}
	.lead{
		color: $gris-texto1;
		line-height: 1.8;
		font-size: 18px;
		margin-bottom: 10px;
		strong{
			font-weight: 400;
			color: $gris-texto3;
		}
	}
	h2,
	h3,
	h4{
		margin:.5em 0;
	}
	h3{
		color: #E10F29;
		font-weight: 400;
	}
	ul{
		list-style: none;
	}
	ol{
		list-style: none;
		counter-reset:li;
		li::before {
			content: counter(li); color: #E10F29;
  			display: inline-block; width: 1em;
			margin-left: -1em;
		}
		li{
			counter-increment:li;
		}
	}
	ul,ol{
		color: #8a8a8a;
		li{
			margin: .5em 0;
		}
	}
	.text-cita{
		font-weight: 500;
		margin: 0 auto;
		margin-top: 50px;
		margin-bottom: 50px;
		position: relative;
		padding-left: 20px;
		color: #4b5560;
		letter-spacing: -1.5px;
		font-size: 1.571428571428571rem;
		max-width: 500px;
		&::after{
			content: "";
			width: 15%;
			position: absolute;
			height: 130%;
			background:rgba(75, 85, 96, 0.48);
			top: -15%;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			z-index: -5;
			right: 0;
			transform: skew(-30deg);
			opacity: 0.2;
		}
		&::before{
			top: -20px;
			left: -10px;
			content: "“";
			font-size: 4rem;
			position: absolute;
			color: $rojo-derco;
		}
		@media #{$media-tablet}{
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 1.314285714285714rem;
		}
	}
}
.text{
	&-fecha{
		font-weight: 300;
		position: relative;
		color: $gris-texto3;
		font-size: 1.114285714285714rem;
		margin-bottom: 9px;
		&:before{
			content: "";
			height: 1px;
			width: 100px;
			bottom: -5px;
			display: block;
			position: absolute;
			background-color: currentColor;
			@media #{$media-tablet}{
				left: 0;
				right: 0;
				max-width: 100%;
			}
		}
	}
	&-1{
		color: $gris-texto1;
		line-height: 1.6;
		font-size: 1.142857142857143rem;
		strong{
			color: $gris-texto3;
		}
	}

	&-cita{
		font-weight: 500;
		margin-top: 50px;
		margin-bottom: 50px;
		position: relative;
		padding-left: 20px;
		color: $gris-texto3;
		letter-spacing: -1.5px;
		font-size: 1.571428571428571rem;
		&::before{
			top: -20px;
			left: -10px;
			content: "“";
			font-size: 4rem;
			position: absolute;
			color: $rojo-derco;
		}
		@media #{$media-tablet}{
			margin-top: 20px;
			margin-bottom: 20px;
			font-size: 1.314285714285714rem;
		}
	}
}

.attribution{
	font-size: 0.8571428571428571rem;
	padding: 10px;
	img{
		display: inline-block;
	}
}

// Colores
.gris-texto1{color: $gris-texto1}
.gris-texto2{color: $gris-texto2}
.gris-texto3{color: $gris-texto3}
.blanco-texto{color: $blanco}
