.componenteGoogleMap{
    $altoMapa: 600px;
    height: $altoMapa;
    @media #{$media-movil}{
    	height: auto;
    }
    &__controles{
        // text-align: center;
        h3.title-2{
            margin-top: 80px;
            @media #{$media-movil}{
            	margin-top: 10px;
            }
        }
        p{
            padding-left: 2px;
            margin-top: 100px;
            margin-bottom: 10px;
            @media #{$media-movil}{
            	margin-top: 10px;
            }
        }
        label.select{
            width: 100%;
            @media #{$media-movil}{
                float: left;
                width: 80%;
            	margin-bottom: 20px;
                &:before{
                    content: "";
                    left: 100%;
                    width: 50px;
                    height: 100%;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url('../img/icons/icon_select_2.png');
                }
            }
        }

        .googleCheckContainer{
            .googleCheck{
                width: 49%;
                cursor: pointer;
                text-align: left;
                margin-bottom: 5px;
                display: inline-block;
                &::before{
                    content: "";
                    width: 19px;
                    height: 19px;
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    border: 1px solid $gris-texto1;
                    background-position: center center;
                    float: left;
                }
                &.active{
                    &::before{
                        background-image: url('../img/icons/icon_lista-rojo.png');
                    }
                }
                &.items{
                    p{
                        float: left;
                        margin:0;
                    }
                }
                &.item01{
                    p{
                        &::after{
                            content:"";
                            width: 19px;
                            height: 19px;
                        }
                    }
                }
            }
        }
    }
    #map {
		height:$altoMapa;
        @media #{$media-movil}{
        	height: $altoMapa - 200;
        }
	}
}
