header.main_header{
    height: $tamanoMenuLateralup;
    padding-left: $tamanoMenuLateral;
    @media #{$media-movil}{
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        position: fixed;
        background-color: $blanco;
        height: $tamanoMenuLateralMovil;
        padding-left: $tamanoMenuLateralMovil;
        // position: relative;
    }
    .main_header--logo{
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        width: $tamanoMenuLateral;
        height: $tamanoMenuLateralup;
        background-color: $rojo-derco;
        -webkit-display:flex ;
        -moz-display:flex ;
        -ms-display:flex ;
        -o-display:flex ;
        display:flex ;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        -o-justify-content: center;
        justify-content: center;
        a{
            display: block;
            img{
                @media #{$media-movil}{
                    width: 65%;
                }
            }
        }
        @media #{$media-movil}{
            width: $tamanoMenuLateralMovil;
            height: $tamanoMenuLateralMovil;
        }
    }
    .main_header--CotizaM{
        >.boton{
            width: 80%;
            left: 0;
            top: 13px;
            position: absolute;
        }
    }
    .over-header{
        padding: 7px 0;
        padding-left: calc(3% - 10px);
        background-color: $gris-bg1;
        p{
            color: $gris-texto2;
            display: inline-block;
            font-size: 0.8571428571428571em;
            strong,b{
                color: $gris-texto3;
            }
        }
        .header_paises{
            width: 75%;
            display: inline-block;
            font-size: 12px;
            a{
                margin: 0 1.5em;
            }
        }
        .header_redes{
            text-align: center;
            display: inline-block;
            .icon.icon__redes{
                width: 15px;
                height: 15px;
                border-radius: 0;
                background-size: cover;
                &.icon__redes--twitter{background-image: url('../img/icons/icon_twitter--header.png');}
                &.icon__redes--facebook{background-image: url('../img/icons/icon_facebook--header.png');}
                &.icon__redes--instagram{background-image: url('../img/icons/icon_instagram--header.png');}
            }
        }
        @media #{$media-tablet}{
            display: none;
        }
    }
    .mainMenu{
        background-color: $blanco;
        @media #{$media-movil}{
            left: 0;
            width: 100%;
            z-index: 100;
            overflow-y: auto;
            position: absolute;
            top: $tamanoMenuLateralMovil;
            max-height: calc(100vh - #{$tamanoMenuLateralMovil});
        }
        ul{
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $gris-bg1;
            padding-left: 3%;
            li{
                margin: 0;
                padding: 0;
                height: 85px;
                list-style: none;
                display: inline-block;
                @media #{$media-movil}{
                    width: 100%;
                    height: 50px;
                }
                a{
                    height: 80%;
                    display: block;
                    @include transition(0.3);
                    padding: 34px 18px 10px 18px;
                    font-weight: 500;
                    color: $gris-texto3;
                    font-size: 1rem;
                    &:hover{
                        color: $rojo;
                    }
                    @media #{$media-movil}{
                        padding: 18px 10px 20px 10px;
                    }
                }
                &.mainMenu__colapse{
                    >a{
                        &:after{
                            font: normal normal normal 14px/1 FontAwesome;
                            content: "\f107";
                            margin-left: 5px;
                            @include transition(0.3);
                            font-size: 16px;
                        }
                    }
                    &.active{
                        >ul{
                            @include flexbox;
                            @media #{$media-movil}{
                                display: block;
                            }
                        }
                        >a{
                            color: $rojo;
                            &:after{
                                display: inline-block;
                                transform: rotate(180deg);
                            }
                        }
                    }
                    // ↓Desplegable
                    >ul{
                        left: 0;
                        top: $tamanoMenuLateralup;
                        width: 100%;
                        padding-left: 0;
                        z-index: 110;
                        display: none;
                        position: absolute;
                        background-color: #fff;
                        @include justify-content(space-around);
                        @media #{$media-movil}{
                            position: static;
                        }
                        li{
                            width: 100%;
                            height: auto;
                            display: block;
                            text-align: center;
                            border-right: 1px solid $gris-bg1;
                            // ↓↓ Iconos
                            &.itema,
                            &.itemm,
                            &.itemr{
                                a{
                                    &::before{
                                        content: "";
                                        width: 37px;
                                        height: 30px;
                                        margin-right: 10px;
                                        display: inline-block;
                                        vertical-align: middle;
                                    }
                                }
                            }
                            &.itema a::before{background-image: url('../img/menu/icon_lateral_1--black.svg');}
                            &.itemm a::before{background-image: url('../img/menu/icon_lateral_2--black.svg');}
                            &.itemr a::before{background-image: url('../img/menu/icon_lateral_3--black.svg');}
                            @media #{$media-movil}{
                                overflow: hidden;
                                text-align: left;
                            }
                            a{
                                padding: 37px 10px;
                                font-weight: 900;
                                @media #{$media-movil}{
                                    padding: 15px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mainMenu__linksFlotantes{
            float: right;
            margin-top: 25px;
            a{
                font-weight: 500;
                color: $gris-texto3;
            }
        }
    }
}

// ↓-- EstilosHamburguesa Boostrap --↓
.navbar-collapse{
    max-height: none;
    &.in{
        overflow-y: inherit;
    }
}
.navbar-toggle{
    margin-top: 20px;
    outline: none;
    .icon-bar{
        background-color: $gris-bg3;
    }
}
