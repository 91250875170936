form{
	display: block;
	width: 100%;
	label{
		position: relative;
	}
	select,
	input[type='text'],
	input[type='password'],
	input[type='date'],
	input[type='phone'],
	input[type='number'],
	input[type='email'],
	textarea{
		width: 100%;
		border: 1px solid $gris-linea1;
		outline: none;
		padding: 10px;
		font-size: 18px;
		@include placeholder{
			color: $gris-linea1;
			font-style: italic;
			font-size: 0.9rem;
		};
	}
	label.newsletter{
		display: block;
		button[type="submit"]{
			width: 40px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			background-color: $negro;
			border: none;
			color: $blanco;
			&:after{
				content: ">";
				font-size: 1.2em;
				line-height: 1px;
			}
		}
		input[type="email"]{
			padding-right: 45px;
		}
	}
}
.wpcf7-select{
	color:#9a9d9e;
}
label.select{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	&::after{
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		width: 40px;
		height: 100%;
		cursor: pointer;
		margin-top: auto;
		border: 1px solid $gris-linea1;
		border-left: none;
		position: absolute;
		margin-bottom: auto;
		background-color: $blanco;
		background-size: 11px auto;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url('../img/icons/icon_acordionList.svg');
		pointer-events: none;
	}
	select{
		width: 100%;
		outline: none;
		padding: 15px;
		font-size: 18px;
		cursor: pointer;
		padding-right: 40px;
		border: 1px solid $gris-linea1;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			padding-right: 0;
		}
		option{
			height: 30px;
		}
	}
}


.wpcf7{
	color: $gris-texto2;
	label{
		display: block;
	}
	label.select, span.wpcf7-form-control-wrap{
		width: 100%;
	}
	p{
		font-weight: 600;
		font-size: 0.9rem;
		margin-bottom: 5px;
		color: $gris-texto1;
	}
}
