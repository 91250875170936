.nuestraHistoria{
    margin-top: 70px;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently*/
    &__imagen{
    }
    .item{
        .title-3{
            @media #{$media-carrousel}{
                text-align: center;
                font-size: 22px;
            }
        }
        .title-fecha{
            @media #{$media-carrousel}{
                text-align: center;
                font-size: 28px;
                margin: .3em;
            }
        }
    }
    &__descripcion{
        min-height: inherit;
        background-repeat: no-repeat;
        background-position: top center;
        background-image: url('../img/nuestra_historia/bg.png');
        @media #{$media-movil}{
            min-height: none;
            background-image: none;
        }
        .title-fecha{
            color: $rojo;
            font-size: 7.5rem;
            line-height: 6rem;
            @include montserrat-bold;
            @media #{$media-movil}{
            	font-size: 1.5rem;
                line-height: 1.8rem;
            }
        }
    }
    &__barraAnuario{
        overflow-x: auto;
        margin-top: 30px;
        padding-top: 30px;
        text-align: center;
        white-space:nowrap;
        overflow-y: hidden;
        border-bottom: 4px solid $gris-bg1;
        &--fecha{
            height: 27px;
            line-height: 27px;
            color: #7c96ac;
            cursor: pointer;
            width: (96%) / 7;
            min-width: 90px;
            position: relative;
            margin-bottom: 20px;
            display: inline-block;
            @include transition(0.3);
            &:before, &:after{
                content: "";
                left: 0;
                right: 0;
                display: block;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
            }
            &:before{
                top: 100%;
                width: 3px;
                height: 27px;
                background-color: $blanco;
            }
            &:after{
                display: none;
                width: 0;
                height: 0;
                top: -15px;
                border-style: solid;
                border-width: 10px 7.5px 0 7.5px;
            }
            &.active{
                color: $rojo;
                font-size: 1.375rem;
                @include montserrat;
                &:before{
                    background-color: currentcolor;
                }
                &:after{
                    display: block;
                    border-color: currentcolor transparent transparent transparent;
                }
            }
        }
    }
}
